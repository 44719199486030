<script setup lang="ts"></script>

<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1525_13629)">
            <path
                d="M14.9438 9.11625C14.8426 8.8925 14.6201 8.75 14.3751 8.75H11.8751V0.625C11.8751 0.28 11.5951 0 11.2501 0H8.75008C8.40508 0 8.12508 0.28 8.12508 0.625V8.75H5.62508C5.38008 8.75 5.15758 8.89375 5.05633 9.11625C4.95383 9.34 4.99383 9.60125 5.15508 9.78625L9.53008 14.7863C9.64883 14.9225 9.82008 15 10.0001 15C10.1801 15 10.3513 14.9213 10.4701 14.7863L14.8451 9.78625C15.0076 9.6025 15.0451 9.34 14.9438 9.11625Z"
                fill="#C1D1E8"
            />
            <path
                d="M16.875 13.75V17.5H3.125V13.75H0.625V18.75C0.625 19.4412 1.185 20 1.875 20H18.125C18.8162 20 19.375 19.4412 19.375 18.75V13.75H16.875Z"
                fill="#C1D1E8"
            />
        </g>
        <defs>
            <clipPath id="clip0_1525_13629">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
